<template>
    <div>
        <HeroSmall
          :page_title="page_title"
          :leadline="leadline"
        />
        <div class="container pt-lg ">
          <div class="row justify-content-center">
            <div class="col-xs-12">
              <picture class="float-md-left pb-xs-5 pb-md-4 pr-md-5">
                <source srcset="img/theme/smiling_woman.jpg 1x, img/theme/smiling_woman@2x.jpg 2x"/>
                <img class="img-fluid" src="img/theme/smiling_woman.jpg" alt="Checking a home health patient" title="Checking in on a patient - HealthView" />
              </picture>
              <p class="lead mt-xs-4 mt-md-0">HealthView Home Health & Hospice thinks of our patients as family. As a locally owned and operated agency, we provide excellent in-home care to you … our neighbors. </p>
              <p>Our compassionate, professional staff considers the invitation to care for you in your home an honor and privilege. We work daily to improve your health outcomes and to increase your quality of life. </p>
              <p>HealthView professionals partner with your primary care provider to create a customized plan of care designed to bring you back to health. Your Plan of Care can include education regarding your health status, an assessment of your home’s safety with regards to falls, and skilled visits from medical professionals, such as nurses and physical therapists. All of these components work in coordination to compliment your rehabilitation and recovery. </p>
              <p>HealthView Home Health & Hospice is Medicare-certified and licensed by the state of North Carolina. Our staff is available 24 hours a day, 7 days a week, everyday of the year, even on holidays. </p>              
            </div>
          </div>
        </div>
        <ServicesCards />
    </div>
</template>
<script>
import HeroSmall from '../views/components/HeroSmall.vue'
import ServicesCards from '../views/components/ServicesCards.vue'
export default {
  name: 'about',
  metaInfo: {
    title: 'About HealthView Home Health, Previously Nash Home Health',
    meta: [
      {
        'description':'HealthView Home Health & Hospice purchased Nash Home Health in 2018. We are committed to providing exceptional care to patients in Nash County and now nine additional counties.'
      }
    ]
  },
  components: {
    HeroSmall,
    ServicesCards
  },
  data: () => ({
    page_title: 'About Us',
    leadline: 'HealthView Home Health &amp; Hospice'
  }),
  methods:{
    goTo(path){
      this.$router.push({path: path})
    }
  }
};
</script>
<style>
</style>