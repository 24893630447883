<template>
    <div>
        <HeroSmall
          :page_title="page_title"
          :leadline="leadline"
        />
        <div class="container pt-lg ">
          <div class="row justify-content-center">
            <div class="col-xs-12 px-3 px-md-0">
              <p>
                HealthView Home Health & Hospice has two offices in the state – Rocky Mount and Yanceyville. Those offices serve 16 counties in the surrounding areas. Those counties include <strong>Alamance, Caswell, Edgecombe, Franklin, Guilford, Halifax, Johnston, Nash, Orange, Person, Rockingham, Vance, Wake, Warren, Wayne, and Wilson.</strong>
              </p>
              <p>
                If you have any question as to whether you or your loved one is included in our service area, please call us at <a href="tel:1-252-462-2687">(252) 462-2687</a>.
              </p>
            </div>
          </div>
          <div class="row justify-content-center mb-5">
            <div class="col-xs-12 px-3 px-md-0">
                <img
                  class="img-fluid"
                  srcset="img/healthview_service_area_2022@0.5x.png, img/healthview_service_area_2022.png 1.5x, img/healthview_service_area_2022@2x.png 2x"
                  alt="Map of Healthview Service areas in North Carolina"
                  />
            </div>
          </div>
        </div>
        <ServicesCards />
        <section class="section">
          <hr>
          <div class="row justify-content-center">
            <div class="col-lg-5">
                <card shadow class="shadow-lg--hover mt-4">
                  <p>Are you committed to providing high quality, comprehensive health care? </p>
                  <router-link class=" btn btn-info btn-block" to="/employment">Join Our Team</router-link>
                </card>
            </div>
          </div>
        </section>
    </div>
</template>
<script>
import HeroSmall from '../views/components/HeroSmall.vue'
import ServicesCards from '../views/components/ServicesCards.vue'
export default {
  name: 'service-areas',
  metaInfo: {
    title: 'Service Areas... beyond Nash County',
    meta: [
      {
        'description': 'Based out of Rocky Mount, we provide home health care in Alamance, Caswell, Edgecombe, Franklin, Guilford, Halifax, Johnston, Nash, Orange, Person, Rockingham, Vance, Wake, Warren, Wayne, and Wilson. counties.'
      }
    ]
  },
  components: {
    HeroSmall,
    ServicesCards
  },
  data: () => ({
    page_title: 'Service Areas',
    leadline: 'HealthView Home Health &amp; Hospice'
  }),
  methods:{
    goTo(path){
      this.$router.push({path: path})
    }
  }
};
</script>
